<template>
    <div>
        <ModalUpload :visible="modalUploadVisible" :client-id="clientId" @close="modalUploadVisible = false" @uploaded="fileUploaded" />
        <ModalEdit :visible="modalEditVisible" :accounts-data="accountsData" :selected-account="selectedAccount" @close="modalEditVisible = false" @updated="updateData" />

        <div class="card-tail flex justify-between">
            <div>
                <p v-show="fileExtraInfo.companyName">
                    Företag: {{ fileExtraInfo.companyName }} ({{ fileExtraInfo.number }}), omfång: {{ fileExtraInfo.start }} /
                    {{ fileExtraInfo.end }}
                </p>
                <p v-show="fileExtraInfo.companyName">Program: {{ fileExtraInfo.program }}</p>
            </div>
            <!-- <el-button type="primary" @click="clearSaved">Clear</el-button>
            <el-button type="primary" @click="saveForLater">Save for later</el-button> -->
            <div>
                <el-button type="primary" @click="showModalUpload">Ladda upp fil</el-button>
                <el-button type="primary" @click="save">Spara</el-button>
            </div>
        </div>

        <div v-if="importedData.note" class="flex justify-center">
            <p class="bg-red-500 text-white py-2 px-4 rounded mb-2 shadow-sm">
                {{ importedData.note }}
            </p>
        </div>

        <div class="grid grid-cols-2 gap-3">
            <el-tabs>
                <el-tab-pane label="Results">
                    <div class="card-tail">
                        <ResultsSection :results-data="importedData.results" :accounts-data="accountsDataFiltered" @select="selectionChanged" />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Balances">
                    <div class="card-tail">
                        <BalancesSection :balances-data="importedData.balances" :accounts-data="accountsDataFiltered" @select="selectionChanged" />
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="card-tail">
                <VouchersSection :vouchers-data="importedData.vouchers" :selected-account="selectedAccount" @editAccount="showModalEdit" @reset="resetAccount" />
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Api from "./lopande_import.api";

export default {
    props: {
        clientId: {
            type: String,
            default: "",
        },
    },

    components: {
        ResultsSection: () => import(/* webpackChunkName: "LopandeImportResults" */ "./LopandeImportResults"),
        BalancesSection: () => import(/* webpackChunkName: "LopandeImportBalances" */ "./LopandeImportBalances"),
        VouchersSection: () => import(/* webpackChunkName: "LopandeImportVouchers" */ "./LopandeImportVouchers"),
        ModalUpload: () => import(/* webpackChunkName: "LopandeImportModalUpload" */ "./LopandeImportModalUpload"),
        ModalEdit: () => import(/* webpackChunkName: "LopandeImportModalEdit" */ "./LopandeImportModalEdit"),
    },

    data() {
        return {
            accountsData: [],
            accountsDataFiltered: [],
            importedData: [],
            importedDataOrigin: [],
            importedDataChanges: [],
            selectedAccount: {
                account: null,
            },
            fileExtraInfo: {
                companyName: null,
                number: null,
                start: null,
                end: null,
                program: null,
            },
            selectedItems: [],
            modalUploadVisible: false,
            modalEditVisible: false,
        };
    },

    created() {
        this.getAccounts();
    },

    methods: {
        async getAccounts() {
            const data = await Api.getAccounts(this.clientId);
            this.accountsData = data;
            this.accountsDataFiltered = data.flatMap(item => item.number);
        },

        showModalUpload() {
            this.modalUploadVisible = true;
        },

        showModalEdit() {
            this.modalEditVisible = true;
        },

        fileUploaded(data) {
            this.importedData = data;
            this.importedDataOrigin = data;
            this.fileExtraInfo = {
                companyName: data.id.companyName,
                number: data.id.number,
                start: moment(data.id.start).format("YYYY-MM-DD"),
                end: moment(data.id.end).format("YYYY-MM-DD"),
                program: data.id.program,
            };
        },

        selectionChanged(data) {
            this.selectedAccount = data;
        },

        updateData(event) {
            const results = { ...this.importedData.results };
            const balances = { ...this.importedData.balances };

            const index1 = this.importedData.results.findIndex(item => item.account === event.accountToChange);
            const index2 = this.importedData.balances.findIndex(item => item.account === event.accountToChange);

            if (index1 > -1) {
                const existingItemIndex = this.importedDataChanges.findIndex(item => {
                    return item.oldAccount === (this.importedDataOrigin.results[index1].oldAccount || this.importedDataOrigin.results[index1].account);
                });
                if (existingItemIndex > -1) {
                    this.importedDataChanges.splice(existingItemIndex, 1);
                }
                this.importedDataChanges.push({
                    oldAccount: this.importedDataOrigin.results[index1].oldAccount || this.importedDataOrigin.results[index1].account,
                    newAccount: event.newValue,
                });

                if (!results[index1].oldAccount) results[index1].oldAccount = results[index1].account;
                results[index1].account = event.newValue;
                this.importedData.results = Object.values(results);
            }

            if (index2 > -1) {
                const existingItemIndex = this.importedDataChanges.findIndex(item => {
                    return item.oldAccount === (this.importedDataOrigin.balances[index2].oldAccount || this.importedDataOrigin.balances[index2].account);
                });
                if (existingItemIndex > -1) {
                    this.importedDataChanges.splice(existingItemIndex, 1);
                }
                this.importedDataChanges.push({
                    oldAccount: this.importedDataOrigin.balances[index2].oldAccount || this.importedDataOrigin.balances[index2].account,
                    newAccount: event.newValue,
                });

                if (!balances[index2].oldAccount) balances[index2].oldAccount = balances[index2].account;
                balances[index2].account = event.newValue;
                this.importedData.balances = Object.values(balances);
            }

            this.selectedAccount.account = null;
        },

        async resetAccount(account) {
            const data = { ...this.importedData };
            const dataChanges = [...this.importedDataChanges];

            const index1 = this.importedData.results.findIndex(item => item.oldAccount === account.oldAccount);
            const index2 = this.importedData.balances.findIndex(item => item.oldAccount === account.oldAccount);

            if (index1 > -1) {
                data.results[index1].account = account.oldAccount;
                delete data.results[index1].oldAccount;
            }

            if (index2 > -1) {
                data.balances[index2].account = account.oldAccount;
                delete data.balances[index2].oldAccount;
            }

            const existingItemIndex = dataChanges.findIndex(item => {
                return item.oldAccount === account.oldAccount;
            });
            dataChanges.splice(existingItemIndex, 1);
            this.importedDataChanges = dataChanges;

            this.importedData.results = [];
            this.importedData.balances = [];

            await this.$nextTick();

            this.importedData.results = data.results;
            this.importedData.balances = data.balances;

            this.selectedAccount.account = null;
        },

        async save() {
            await Api.save(this.clientId, this.importedDataOrigin, this.importedDataChanges);
            this.resetState();
            this.$notify.success({ title: "Utfört" });
        },

        resetState() {
            this.importedData = [];
            this.fileExtraInfo = {
                companyName: null,
                number: null,
                start: null,
                end: null,
                program: null,
            };
        },
    },
};
</script>
