import Vue from "vue";

export default {
    uploadFile: async ({ clientId, file }) => {
        try {
            const { data } = await Vue.axios.post(`clients/${clientId}/bookingFinancialYears/import/upload`, file, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande_import.api.js ~ uploadFile: ~ error", error);
            throw error;
        }
    },

    getAccounts: async clientId => {
        try {
            const { data } = await Vue.axios.get(`booking/accounts/${clientId}`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande_import.api.js ~ getAccounts: ~ error", error);
            return [];
        }
    },

    save: async (clientId, importedData, changes) => {
        try {
            await Vue.axios.post(`clients/${clientId}/bookingFinancialYears/import`, {
                importedData,
                changes,
            });
        } catch (error) {
            throw error;
        }
    },
};
